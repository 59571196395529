import { Link as DDSLink, LinkProps as DDSLinkProps } from '@norges-domstoler/dds-components';
import { useLinkClickHandler } from 'react-router-dom';
import { getRelativeUrl } from '../Utils';
import { useExternalUrl } from './useExternalUrl';

type LinkProps = DDSLinkProps & {
    langRef?: React.MutableRefObject<HTMLAnchorElement>;
};

export default function Link(props: LinkProps) {
    const { children, href, langRef, onClick, ...rest } = props;

    const url = getRelativeUrl(href);
    const isExternalUrl = useExternalUrl(href);
    const linkClickHandler = useLinkClickHandler(url);

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (isExternalUrl) {
            if (onClick) onClick(e);
        } else {
            linkClickHandler(e);
            if (onClick) onClick(e);
        }
    };

    return (
        <DDSLink ref={langRef} href={isExternalUrl ? href : url} onClick={handleClick} {...rest}>
            {children}
        </DDSLink>
    );
}
